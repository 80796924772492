.App {
  text-align: center;
}

/* Apply this style globally */
html {
  overflow-y: scroll; /* Always show vertical scrollbar */
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

form div {
  margin: 10px 0;
}

form button {
  padding: 10px 20px;
  background-color: #61dafb;
  border: none;
  cursor: pointer;
}

form button:hover {
  background-color: #21a1f1;
}
