/* General container styling for the home content */
.home {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px; /* Add padding for smaller screens */
  position: relative;
  box-sizing: border-box;
}

.slideshow-container {
  position: relative;
  width: 100%;
}

/* Ensure images fit the container */
.slick-slide img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
}

/* Remove default arrow styles */
.slick-prev, .slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000; /* Ensure it is above other content */
  cursor: pointer;
  color: black; /* Arrow color */
  font-size: 30px; /* Adjust the font size for the arrows */
  background: transparent; /* Remove background */
  border: none; /* Remove border */
  box-shadow: none; /* Remove any potential box-shadow */
}

/* Ensure no additional white arrows */
.slick-prev::before, .slick-next::before {
  display: none; /* Hide default arrow icons */
}

/* Arrow positions */
.slick-prev {
  left: 10px; /* Adjust position to move left arrow inside */
}

.slick-next {
  right: 10px; /* Adjust position to move right arrow inside */
}

/* Container for each section (left and right image) */
.container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  align-items: center;
  gap: 20px; /* Space between the text and image */
  margin: 40px 0;
}

.content-text h2 {
  color: #ff4500; /* Example color: You can change this to any color you like */
  font-weight: bold; /* Optional: Make the heading bold */
  font-size: 24px; /* Optional: Adjust the font size */
}

/* Left Image and Right Text */
.leftimage-container .content-image {
  order: 1; /* Image first in the grid */
}

.leftimage-container .content-text {
  order: 2; /* Text second in the grid */
}

/* Right Image and Left Text */
.rightimage-container .content-image {
  order: 2; /* Image second in the grid */
}

.rightimage-container .content-text {
  order: 1; /* Text first in the grid */
}

/* Style for the text section */
.content-text {
  padding: 20px;
  font-size: 18px;
  text-align: left; /* Align text to the left */
  line-height: 1.6; /* Improve readability */
}

/* Style for the image section */
.content-image {
  text-align: center;
}

/* Ensure images are responsive */
.content-image img {
  max-width: 100%;
  width: 50%; /* Reduced image size */
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow for a floating effect */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .home {
    padding: 0 10px; /* Adjust padding for mobile screens */
  }

  .container {
    grid-template-columns: 1fr; /* Stack text and image in one column */
    text-align: center; /* Center text and image for mobile */
  }

  .content-image img {
    width: 80%; /* Adjust image size on mobile */
    margin-bottom: 20px; /* Space between the image and text when stacked */
  }

  .content-text {
    text-align: center; /* Center align text on mobile */
  }
}
