/* Icon Styles */
.contact-icon {
  font-size: 28px;
  margin-right: 10px;
  color: black;
}

/* General Container Styles */
.contact-us-container {
  padding: 15px;
  max-width: 1200px;
  margin: 0 auto;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Heading Styles */
.contact-us-heading {
  text-align: center;
  margin-bottom: 15px;
  font-size: 1.5em;
}

/* Layout for Contact Details and Form */
.contact-us-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  width: 100%;
}

/* Contact Details Styles */
.contact-details {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 15px;
  font-size: 16px;
}

.contact-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.contact-info h2 {
  margin: 0;
  font-size: 20px;
}

.contact-info p {
  margin: 0;
  font-size: 16px;
}

/* Contact Form Styles */
.contact-form {
  display: flex;
  flex-direction: column;
  padding: 15px;
  font-size: 16px;
  align-items: flex-start;
  width: 100%;
}

.contact-form h2 {
  margin-bottom: 10px;
  font-size: 20px;
  text-align: left;
}

/* Form Row Styles */
.form-row {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.form-group {
  flex: 1;
  min-width: 200px;
}

/* Styles for Labels and Inputs */
.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  text-align: left;
}

.form-group input,
.form-group textarea {
  width: 100%;
  max-width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: left;
}

/* Adjust Message Field to Match Other Inputs */
.form-group-fullwidth {
  width: 100%;
}

.form-group textarea {
  height: 100px;
  resize: vertical;
  text-align: left;
}

/* Button Styling */
button {
  padding: 8px 16px;
  border: none;
  background-color: #4CAF50;
  color: white;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 15px;
  align-self: flex-start;
}

button:hover {
  background-color: #45a049;
}

/* Map Container Styles */
.map-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.map-container iframe {
  width: 100%;
  height: 250px;
  border: none;
  border-radius: 8px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .contact-us-content {
    grid-template-columns: 1fr;
    text-align: left;
  }

  .form-row {
    flex-direction: column;
  }

  .contact-form, .contact-details {
    align-items: flex-start;
    text-align: left;
  }

  .form-group input, .form-group textarea {
    width: 100%;
  }

  button {
    align-self: flex-start;
  }
}
