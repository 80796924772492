/* General Navbar Styles */
.navbar {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  z-index: 10000;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

/* Styling for the logo */
.navbar-logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo-image {
  height: 60px;
  width: auto;
}

/* Navbar Links Styles */
.navbar-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
  gap: 15px;
}

/* Hide navbar links by default on mobile */
.navbar-links {
  display: flex;
}

.hamburger {
  display: none;
  font-size: 1.5em;
  cursor: pointer;
}

/* Styling for each navbar link */
.navbar-links li {
  position: relative;
}

/* Styling for links inside the navbar */
.navbar-links li a {
  display: block;
  color: rgb(19, 19, 19);
  text-align: center;
  padding: 10px 16px;
  text-decoration: none;
  transition: color 0.3s ease, background-color 0.3s ease;
  font-weight: 600;
  font-size: 1.1em;
  box-sizing: border-box;
}

/* Smooth underline for navbar items */
.navbar-links > li > a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: black;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

/* Hover state for the underline */
.navbar-links > li > a:hover::before,
.navbar-links > li > a.active::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* Dropdown Container */
.dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  padding: 10px;
  box-sizing: border-box;
  white-space: nowrap;
  display: flex;
  gap: 20px;
  min-width: 200px;
}

/* Dropdown Section */
.dropdown-section {
  display: flex;
  flex-direction: column;
  text-align: left;
}

/* Dropdown Items (Body Text) */
.dropdown-section ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/* Dropdown List Items */
.dropdown-section li {
  padding: 0;
  margin-bottom: 5px;
}

/* Dropdown Item Links */
.dropdown-section li a {
  color: rgb(19, 19, 19);
  text-decoration: none;
  display: block;
  padding: 5px;
  font-size: 1em;
  font-weight: normal;
  transition: color 0.3s ease;
  text-align: left;
}

/* Hover Effect */
.dropdown-section li a:hover {
  color: #ff9800;
}

/* Show Dropdown on Hover */
.dropdown-container:hover .dropdown {
  display: flex;
}

/* Responsive Styles */
@media (max-width: 768px) {
  /* Show hamburger icon on mobile */
  .hamburger {
    display: block;
    position: relative;
  }

  /* Hide default navbar links */
  .navbar-links {
    display: none;
    flex-direction: column;
    gap: 0;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    padding: 20px;
    z-index: 9999;
  }

  /* Show navbar links when active */
  .navbar-links.active {
    display: flex;
  }

  /* Display Indoor above Outdoor in mobile */
  .dropdown {
    flex-direction: column;
  }

  .dropdown-section {
    width: 100%;
  }

  /* Adjust link styles for mobile */
  .navbar-links li a {
    padding: 10px 0;
    font-size: 1em;
    width: 100%;
    text-align: left;
    font-weight: 500;
  }
}
